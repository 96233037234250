.Home {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 48px;

  margin-top: 72px;
}

.example-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: black;
  text-decoration: none;

  font-size: xx-large;

  cursor: pointer;
}

.icon {
  font-size: 100px;
}